import {LoadableComponent} from '@loadable/component'
import {useStyles} from '@wix/tpa-settings/react'
import {LIST_LAYOUT, MOBILE_LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {useEnvironment, usePanorama} from '@wix/yoshi-flow-editor'
import React from 'react'
import {LoadableSuspenseWithPreviousFallback} from '../../../../../commons/components/loadable-suspense-with-previous-fallback'
import stylesParams from '../../../stylesParams'
import {
  Calendar,
  Cards,
  CardsMobile,
  CompactMobile,
  EmptyState,
  ListLayout,
  OnImage,
  SideBySide,
  SingleEvent,
} from './chunks'
import {LayoutProps} from './interfaces'

export const Layout = ({listLayout, singleEventWidget, emptyStateVisible, narrowLayout}: LayoutProps) => {
  const {isEditor, isMobile: mobile, isSSR} = useEnvironment()
  const mobileListLayout = useStyles().get(stylesParams.mobileListLayout)
  const logger = usePanorama()?.logger?.()

  const getLayout = (): LoadableComponent<any> => {
    if (emptyStateVisible) {
      return EmptyState
    }

    if (singleEventWidget) {
      return SingleEvent
    }

    if (mobile) {
      if (listLayout === LIST_LAYOUT.CALENDAR) {
        return Calendar
      }
      switch (mobileListLayout) {
        case MOBILE_LIST_LAYOUT.COMPACT:
          return CompactMobile
        case MOBILE_LIST_LAYOUT.ON_IMAGE:
          return OnImage
        default:
          return CardsMobile
      }
    }

    switch (listLayout) {
      case LIST_LAYOUT.GRID:
      case LIST_LAYOUT.NEW_GRID:
        return Cards
      case LIST_LAYOUT.SIDE_BY_SIDE:
        return SideBySide
      case LIST_LAYOUT.CALENDAR:
        return Calendar
      case LIST_LAYOUT.ON_IMAGE:
        return OnImage
      default:
        if (narrowLayout) {
          if (!isSSR) {
            logger?.debug?.('Narrow layout widget')
          }
          return CardsMobile
        }
        return ListLayout
    }
  }

  const Component = getLayout()
  if (isEditor) {
    return <LoadableSuspenseWithPreviousFallback Component={Component} />
  }
  return <Component />
}
